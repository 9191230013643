@import '../../vars.scss';

.Monitor {
  display: flex;
  background-color: $brandLightGrey;

  .Content {
    background: white;
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding: 16px;

    @media (max-width: 600px) {
      width: 600px;
    }
    
    .StatusTags {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      align-items: center;
    }

    pre {
      background: #ddd;
      padding: 10px;
    }

    .websocket-status {
      background: #ddd;
      text-align: center;
      padding: 10px;
    }

    .message {
      border: 1px solid;
      padding: 10px;
      margin-top: 10px;
    }

    .message h4 {
      margin: 10px 0;
    }

    .messages {
      overflow-y: auto;
    }

    .tree {
      font-family: monospace;
    }

    .faded {
      opacity: 0.5;
    }

    .tag {
      background: #ccc;
      border-radius: 100px;
      display: inline-block;
      font-size: 12px;
      color: white;
      padding: 2px 8px;
      margin-right: 1ch;
    }

    .tag.success {
      background-color: green;
    }

    .tag.warning {
      background: orange;
    }

    .tag.danger {
      background: orangered;
    }

    .EnvironmentSelector .RadioGroup {
      padding: 1ch 0;
    }

    .EnvironmentSelector .RadioGroup>label {
      margin-right: 2ch;
    }

    .EnvironmentSelector .RadioGroup>label>span {
      margin-left: 1ch;
    }

    .ApiTokenInput,
    .EnvironmentSelector {
      display: flex;
      flex-direction: column;
    }

    .ApiTokenInput>label,
    .EnvironmentSelector>label {
      width: 24ch;
      flex: unset;
      font-weight: bold;
    }

    .ApiTokenInput>div {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .ApiTokenInput>div>* {
      flex: 1;
    }

    .ApiTokenInput textarea {
      margin-top: 1ch;
      padding: 1ch;
      display: block;
      height: 7ch;
    }

    .Code {
      font-size: 10px;
      display: block;
      padding: 2ch;
      white-space: pre;
    }

    .ActionsPanel {
      display: flex;
      border: 1px solid #eee;
      padding: 1ch;
      margin-bottom: 1ch;
      border-radius: 1ch;
      ;
      align-items: center;
    }

    .ActionsPanel>.Title:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    .ActionsPanel>* {
      margin-right: 1ch;
    }

    .ActionsPanel>*:nth-last-child(1) {
      margin-right: 0;
    }

    .ActionsPanel .button {
      align-self: center;
    }

    .button {
      cursor: pointer;
      display: block;
      color: white;
      padding: 1ch;
      background: green;
      border-bottom: 2px solid rgb(1, 99, 1);
      text-align: center;
      margin-bottom: 1ch;
      margin-right: 1ch;
      height: 1em;
    }

    .button:hover {
      background: rgb(0, 155, 0);
    }

    .button:active {
      background: rgb(0, 185, 0);
    }

    .Globals {
      display: flex;
      flex-direction: column;
    }

    .Globals>* {
      flex: 1;
    }

    .ConsoleLogs {
      font-size: 12px;
      height: 400px;
      color: white;
      background: rgb(42, 65, 42);
      margin: 2ch 0;
      overflow-y: scroll;
    }

    .ConsoleLogs span {
      display: block;
      margin: 4px 0;
    }

    .SessionsView .Heading {
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .SessionsView {
      margin: 2ch 0;
      // border: 2px solid #ccc;
    }

    .SessionsView .UID {
      font-weight: bold;
    }

    .SessionsView .SessionDetailView {
      display: flex;
      flex-direction: column;

      .Header {
        text-transform: uppercase;
        padding: 1ch 10px;
      }

      .Header:hover {
        background: $brandBlue;
        cursor: pointer;
      }
    }

    .SessionsView .SessionDetailView .Content {
      display: flex;
      flex-direction: row;
      margin-left: 2ch;
      margin-right: 2ch;
      margin-top: 1ch;
    }

    .SessionsView .SessionDetailView .SessionData .State {
      color: white;
      padding: 1ch 2ch;
      display: inline-block;
      background: green;
      border-radius: 1ch;
      font-size: 12px;
      margin-bottom: 1ch;
    }

    .SessionsView .SessionDetailView .SessionData .State.IDLE {
      background: rgb(65, 168, 202)
    }

    .SessionsView .SessionDetailView .SessionData .State.WaitingForClaim {
      background: green
    }

    .SessionsView .SessionDetailView .SessionData .State.WaitingForDriverConfirmation {
      background: rgb(109, 128, 0)
    }

    .SessionsView .SessionDetailView .SessionData .State.Failed {
      background: orangered
    }

    .SessionsView .SessionDetailView .SessionData .State.WaitingForWeighbridgeReceipt {
      background: rgb(0, 128, 100)
    }

    .SessionsView .SessionDetailView .SessionData .State.SendingCompletedEvents {
      background: orange
    }

    .SessionsView .SessionDetailView .SessionData .State.Finished {
      background: rgb(65, 168, 202)
    }

    .SessionsView .SessionDetailView .SessionData::before {
      display: block;
      margin-bottom: 1ch;
      content: 'State';
      font-weight: bold;
    }

    .SessionsView .SessionDetailView .SessionData>* {
      display: block;
      padding: 0;
    }

    .SessionsView .SessionDetailView .ClientList::before {
      display: block;
      margin-bottom: 1ch;
      content: 'Devices';
      font-weight: bold;
    }

    .SessionsView .SessionDetailView .ClientList>* {
      padding: 0;
      margin-bottom: 1ch;
    }

    .ExpandableThingoItem:hover,
    .ExpandableThingoItem>span:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    .ExpandableThingoItem {
      display: block;
      padding: 0;
      margin: 0;
      font-size: 12px;
    }

    .ExpandableThingoItem>span:nth-child(1) {
      display: inline-block;
      width: 18ch;
    }

    .ExpandableThingoItem>span:nth-last-child(1) {
      // border: 1px solid #ccc;
      // border-radius: 1ch;
      width: unset;
    }

    /* 
  .ImageContainer {
    border: 2px solid red;
  }
  .ImageContainer img {
    border: 2px solid blue;
  } */

    .QueuePanel {
      .Clients {
        display: flex;
        flex-direction: column;

        p {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          margin: 5px 0;

          .tag {
            text-align: center;
            width: 50px;
          }

          .tag.driver {
            background-color: $brandGreen;
          }

          .tag.identity {
            background-color: $brandBlue;
          }

          .Line {
            flex: 1;
            display: block;
            border-bottom: 2px solid #eee;
            margin-left: 10px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}