.IndicatorContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 12px;

  @media (max-width: 600px) {
    flex-direction: column;
  }

  .Indicator {
    display: flex;
    width: 150px;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 12px;

    @media (max-width: 600px) {
      width: 100%;
    }

    &.offline {
      background-color: rgb(255, 213, 213);
    }

    .WeighbridgeName {
      font-weight: bold;
    }
  
    .StatusRow {
      flex: 1;
      display: flex;
      flex-direction: column;

      span {
        padding-bottom: 8px;
      }
    }
  
    .IndicatorView {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 16px;
      background: rgb(3, 37, 0);
      color: rgb(0,255,0);
      font-size: 24px;
    }

    .Tags {
      & > span {
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 8px;
        margin: 2px 1px 1px 1px;
        padding: 4px 8px;
        background-color: white;
      }
    }
  }
}