@import '../../../vars.scss';

.TextInput {
  display: flex;

  & + .TextInput {
    margin-top: $padding;
  }

  span {
    @include Label();
    margin-bottom: 4px;
  }
  
  &.horizontal {
    flex-direction: row;
    align-items: center;
  }

  &.vertical {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }

  input {
    height: 44px;
    box-sizing: border-box;
    border: 1px solid $borderGrey;
    border-width: 0 0 1px 0;
    padding: 0 2ch;
    font-size: 14px;
    transition: 200ms;

    &:focus {
      border-color: $brandBlue;
      outline: none;
    }

    &:disabled {
      color: $brandDarkGrey;
      box-shadow: none;
      background: $brandLightGrey;
      border-color: transparent;
    }
  }

  &.rounded {
    input {
      border-radius: 100px;
      border-width: 1px;
      padding: 0 $paddingMedium;
    }
  }
}