@import '../../../vars.scss';

.Header {
  background: $brandDarkGrey;
  padding: $paddingMedium $paddingLarge;
  display: flex;
  align-items: flex-start;
  box-shadow: 0 1px 10px #0001;
  z-index: 1;
  color: white;

  img {
    padding-top: 6px;
  }

  .logoSection {
    flex: 1 0 auto;
  }

  .accountSection {
    flex: 1 0 auto;
    text-align: right;
  }

  nav {
    flex: 0 1 1000px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
  }

  .navlink {
    font-family: 'Lustria', serif;
    font-weight: bold;

    padding: $padding $paddingMedium 8px $paddingMedium;
    margin-right: $padding;

    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
    }

    &.current {
      cursor: default;
    }

    &.disabled {
      opacity: 0.6;
    }

    .navlinktext {
      // border-bottom: 2px solid transparent;
      position: relative;

      &::after {
        border-bottom: 2px solid transparent;
        content: "";
        width: 0;
        position: absolute;
        left: calc(50%);
        bottom: 0px;
        transition: 150ms;
      }
    }

    .badge {
      border: 2px solid $brandDarkGrey;
      padding: 0px 6px;
      border-radius: 100px;
      margin-left: 6px;
    }

    &:hover:not(.disabled), &.current {
      .navlinktext {
        &::after {
          border-bottom-color: $brandBlue;
          left: 0;
          width: 100%;
        }
      }
    }
  }
}