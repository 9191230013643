@import url('https://fonts.googleapis.com/css?family=Inconsolata&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lustria|Montserrat:300,400,600,700,800,900|Open+Sans:300,400,600,700,800&display=swap');
@import './vars.scss';

* {
box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $brandLightGrey;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
  color: $brandDarkGrey;
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}

a.button, button.button {
  height: 44px;

  &.round {
    width: 46px;
    padding: 0;
  }

  padding: 0 30px;
  box-sizing: border-box;
  border: none;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  
  background: $brandBlue;
  color: $brandWhite;

  font-family: $font1;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  user-select: none;


  transition: box-shadow 200ms, opacity 200ms;
  border: 2px solid transparent;

  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 1;
  }

  &:focus {
    border-color: #00000033;
    outline: none;
  }

  &.wide {
    width: 100%;
  }

  &.disabled, &:disabled {
    &, &:hover, &:active {
      cursor: default;
      box-shadow: none;
      opacity: 1;
      color: $borderGrey;
      background: $brandLightGrey;
    }
  }
}

html, body {
  height: 100vh;
  font-family: 'Inconsolata', 'Courier New', Courier, monospace;
}

// .App {
//   display: flex;
//   flex-direction: column;
//   justify-content: stretch;
//   max-width: 1024px;
//   margin: 0 auto;
//   background: #eee;
//   border: 2px solid #444;
//   padding: 20px;
//   box-sizing: border-box;
// }

// .App > * {
//   flex-shrink: 0;
//   overflow-y: hidden;
// }

// .App > .messages {
//   flex-shrink: 1;
//   overflow-x: hidden;
// }

.Active {
  display: flex;
}