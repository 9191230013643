.NodeView {
  display: flex;
  flex-direction: row;
  padding: 1ch;
  font-size: 0.9em;

  .Graph {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;

    .Clients {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      & > * {
        display: block;
        border-radius: 1ch;
        color: white;
        padding: 4px 1ch;
        margin-bottom: 4px;
        margin-left: 4px;
      }

      & > .driver {
        background-color: rgb(44, 187, 139);
      }
      & > .identity {
        background-color: rgb(47, 102, 255);
      }
    }

    .Event {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 1ch 0;
      
      .NonCameraId,
      .CameraId {
        display: flex;

        img {
          width: 250px;
          height: auto;
          margin-right: 1ch;
          border-radius: 1ch;
        }

        // img:hover {
        //   width: 600px;
        //   height: 400px;
        // }
        
        & > div {
          display: flex;
          flex-direction: column;

          & > span:nth-child(1) {
            font-weight: bold;
          }
        }
      }

      .Circle {
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background: lightgreen;
        border: 1px solid rgb(76, 179, 76);
        margin-top: 4px;
      }

      .Circle.Disabled {
        background: rgb(201, 201, 201);
        border: 1px solid rgb(170, 170, 170);
      }

      .Title {
        margin-left: 1ch;
        width: 150px;
        font-weight: bold;
      }

      .Value {
        white-space: pre;
      }
    }
  }
}